document.addEventListener 'DOMContentLoaded', ->
  console.log "Loaded"
  for colorItem in document.querySelectorAll('.js-color')
    do ->
      colorItem.addEventListener 'click', (e) ->
        console.log "e"
        colorItem = e.currentTarget
        color = colorItem.getAttribute('data-color')

        if color is 'gray'
          document.querySelector(".js-black-section").classList.remove 'is-show'
          document.querySelector(".js-gray-section").classList.add 'is-show'
        else
          document.querySelector(".js-gray-section").classList.remove 'is-show'
          document.querySelector(".js-black-section").classList.add 'is-show'
